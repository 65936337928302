import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const IndustriesSection = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            scale: 1.70,
        },
        visible: { 
            opacity: 1, 
            scale: 1,
            transition: {
                duration: 0.5,
                type: 'spring'
            }
        }
    };

    const industriesVariants = {
        hidden: {
            opacity: 0,
            y: 100
        },
        visible: { 
            opacity: 1, 
            y: 0 
        }
    };

    return(
        <div className='industries' ref={ref}>
            <div className='col-12 title-container'>
                <motion.h3 
                    className='title'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    >
                    {i18next.t('industries.title')}
                </motion.h3>
            </div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 software'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.2,
                }}
                >
                    <div className='case'>
                        <span className='icon-software icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.digitalize')}</h4>
                            <p>{i18next.t('industries.digitalize-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 telecom'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.25,
                }}
                >
                    <div className='case'>
                        <span className='icon-telecom icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.telecom')}</h4>
                            <p>{i18next.t('industries.telecom-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ecology'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.3,
                }}
                >
                    <div className='case'>
                        <span className='icon-enivironment icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.ecology')}</h4>
                            <p>{i18next.t('industries.ecology-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 agro'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.35,
                }}
                >
                    <div className='case'>
                        <span className='icon-agriculture icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.agriculture')}</h4>
                            <p>{i18next.t('industries.agriculture-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 transport'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.4,
                }}
                >
                    <div className='case'>
                        <span className='icon-transport icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.transport')}</h4>
                            <p>{i18next.t('industries.transport-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 cartography'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.45,
                }}
                >
                    <div className='case'>
                        <span className='icon-cartography icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.cartography')}</h4>
                            <p>{i18next.t('industries.cartography-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 energy'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.5,
                }}
                >
                    <div className='case'>
                        <span className='icon-energy icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.energy')}</h4>
                            <p>{i18next.t('industries.energy-descr')}</p>
                        </div>
                    </div>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 landUse'
                animate={inView ? 'visible' : 'hidden'}
                variants={industriesVariants}
                transition={{
                    delay: 0.55,
                }}
                >
                    <div className='case'>
                        <span className='icon-land-use icon col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3'></span>
                        <div className='titleDescr col-10 col-sm-10 col-md-10 col-lg-9 col-xl-9'>
                            <h4>{i18next.t('industries.land-use')}</h4>
                            <p>{i18next.t('industries.land-use-descr')}</p>
                        </div>
                    </div>
            </motion.div>
        </div>
    )
};

export default IndustriesSection;