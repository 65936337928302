import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const VisionSection = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const gVariants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        visible: { 
            opacity: 0.3,
            x: 0
        }
    }

    const titleVariants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    };

    const logoVariants = {
        hidden: {
            opacity: 0,
            scale: 0.6
        },
        visible: { 
            opacity: 1,
            scale: 1
        }
    }

    const textVariants = {
        hidden: {
            opacity: 0,
            y: 30
        },
        visible: { 
            opacity: 1,
            y: 0
        }
    }
    
    return (
        <div className='vision' ref={ref}>
            <motion.img 
                src='/images/png/g.png' 
                className='g' 
                alt=' ' 
                animate={inView ? 'visible' : 'hidden'}
                variants={gVariants}
                transition={{
                    duration: 0.7,
                    delay: 0.4,
                    type: 'spring'
                }}
                />
            <motion.div 
                className='title-container col-12 p-0'
                animate={inView ? 'visible' : 'hidden'}
                variants={titleVariants}
                transition={{
                    duration: 0.5,
                    type: 'spring'
                }}>
                <h2 className='title'>{i18next.t('about.vision-title')}</h2>
            </motion.div>
            <div className='logoContainer col-12 p-0'>
                <motion.span 
                    className='icon icon-logo'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={logoVariants}
                    transition={{ duration: 0.5, delay: 0.2, type: "spring" }}
                    >
                    <span className='slider'>
                        <span className='text1'>{i18next.t('about.vision-construction')}</span>
                        <span className='text2'>{i18next.t('about.vision-cadaster')}</span>
                        <span className='text3'>{i18next.t('about.vision-engineering')}</span>
                    </span>
                </motion.span>
            </div>
            <motion.div 
                className='col-12 col-sm-12 col-md-11 col-lg-9 col-xl-7'
                animate={inView ? 'visible' : 'hidden'}
                variants={textVariants}
                transition={{ duration: 0.5, delay: 0.3, type: "spring" }}>
                <h4 className='ourVision'>{i18next.t('about.vision-descr1')}<br /><br />{i18next.t('about.vision-descr2')}</h4>
            </motion.div>
        </div>
    );
};

export default VisionSection;