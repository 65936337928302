import React, { useState } from "react";
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { MDBIcon } from 'mdbreact';

const ContactForm = () => {
    const { register, handleSubmit, reset } = useForm();
    const [requestAsPrivateDisabled, setRequestAsPrivate] = useState(true);
    const [requestAsCompanyDisabled, setRequestAsCompany] = useState(true);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangeEntity = (event) => {
        event.preventDefault();
        if (event.target.value === 'person') {
            setRequestAsCompany(true);
            setRequestAsPrivate(false);
        } else if (event.target.value === 'business') {
            setRequestAsPrivate(true);
            setRequestAsCompany(false);
        } else if (event.target.value === '') {
            setRequestAsCompany(true);
            setRequestAsPrivate(true);
        }
        return;
    };

    const onSubmit = (data) => {
        console.log(data);
        console.log('hey')
        reset();
        setLoading(true);

        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            console.log(xhr.responseText);
        });
        xhr.open('GET', 'https://geocad-ks.com/mail.php?from=' + data.email + 
                                '&city=' + data.city +
                                '&company=' + data.company +
                                '&entity=' + data.entity +
                                '&last-name=' + data.lastName +
                                '&name=' + data.name + 
                                '&request=' + data.request +
                                '&service-company=' + data.serviceCompany +
                                '&service-private=' + data.servicePrivate +
                                '&subject=' + data.subject +
                                '&telephone=' + data.telephone
                                );
        xhr.send();

        setTimeout(() => {
            setSuccessfullySubmitted(true);
            setLoading(false);
        }, 2000);
    };


    return (
        <div className='form-container col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='col-12 p-0'>
                    <div className='col-12 p-0'>
                        <select 
                            className="browser-default custom-select" 
                            ref={register} 
                            name='entity'
                            type='select'
                            onChange={(e) => handleChangeEntity(e)}
                            >
                            <option value=''>{i18next.t('contact.entity')}</option>
                            <option value='person'>{i18next.t('contact.person')}</option>
                            <option value='business'>{i18next.t('contact.business')}</option>
                        </select>
                    </div>
                    <br />
                    <div className='col-12 p-0 dependers'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 random'>
                            <select 
                                className="browser-default custom-select" 
                                ref={register} 
                                name='servicePrivate'
                                type='select'
                                id='privateServices'
                                disabled={requestAsPrivateDisabled}
                                >
                                <option value='E_Pazgjedhur'>{i18next.t('contact.choose-service')}</option>
                                <option value='Ndarje_Parceles'>{i18next.t('contact.ndarje')}</option>
                                <option value='Legalizim'>{i18next.t('contact.legalizim')}</option>
                                <option value='Kufizim_Parcele'>{i18next.t('contact.kufizim')}</option>
                                <option value='Relievim_Parcele'>{i18next.t('contact.relievim')}</option>
                                <option value='Ekspertize'>{i18next.t('contact.ekspertize')}</option>
                                <option value='Te_tjera'>{i18next.t('contact.others')}</option>
                            </select>
                        </div>
                        <br />
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 business'>
                            <select 
                                className="browser-default custom-select" 
                                ref={register} 
                                name='serviceCompany'
                                type='select'
                                id='businessServices'
                                disabled={requestAsCompanyDisabled}
                                >
                                <option value='E_Pazgjedhur'>{i18next.t('contact.choose-service')}</option>
                                <option value='Monitorim_Infrastrukturore'>{i18next.t('contact.monitorim')}</option>
                                <option value='Matje_Topografike'>{i18next.t('contact.topografi')}</option>
                                <option value='Modelim_3D'>{i18next.t('contact.modelim')}</option>
                                <option value='Matje_Ajerore'>{i18next.t('contact.aerial')}</option>
                                <option value='Hartografi'>{i18next.t('contact.hartografi')}</option>
                                <option value='Te_tjera'>{i18next.t('contact.others')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                <div className='col-12 p-0 split'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 left'>
                        <input
                            ref={register({required: { value: true, message: 'Të plotësohet'}})}
                            type="text"
                            name='name'
                            id='name'
                            className="form-control"
                            placeholder={i18next.t('contact.name')}
                            disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                            />
                        <br />
                        <input
                            ref={register({required: { value: true, message: 'Të plotësohet'}})}
                            type="text"
                            name='lastName'
                            id='lastName'
                            className="form-control"
                            placeholder={i18next.t('contact.surname')}
                            disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                            />
                        <br />
                        <input
                            ref={register({required: { value: true, message: 'Të plotësohet'}})}
                            type="text" 
                            name='city'
                            id='city'
                            className="form-control"
                            placeholder={i18next.t('contact.city')}
                            disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                            />
                        <br />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right'>
                        <input
                            ref={register}
                            type="text"
                            name='telephone'
                            id='telephone'
                            className="form-control"
                            placeholder={i18next.t('contact.telephone')}
                            disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                            />
                        <br />
                        <input
                            ref={register({required: { value: true, message: 'Të plotësohet'}})}
                            type="email"
                            name='email'
                            id='email'
                            className="form-control"
                            placeholder={i18next.t('contact.email')}
                            disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                            />
                        <br />
                        <input
                            ref={register}
                            type="text"
                            name='company'
                            id='company'
                            className="form-control"
                            placeholder={i18next.t('contact.company')}
                            disabled={requestAsCompanyDisabled}
                            />
                        <br />
                    </div>
                </div>
                <br />
                <input
                    ref={register}
                    type="text"
                    name='subject'
                    id='subject'
                    className="form-control"
                    placeholder={i18next.t('contact.request-title')}
                    disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                    />
                    <br />
                <textarea
                    ref={register({required: { value: true, message: 'Të plotësohet'}})}
                    type="text"
                    name='request'
                    id='request'
                    className="form-control" 
                    rows="3"
                    placeholder={i18next.t('contact.request')}
                    disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}
                    />
                <br />
                <p className='miniGDPR'>{i18next.t('contact.prior-notice')}</p><br/>
                <div className='col-12 p-0 submitActions'>
                    <div className='buttonContainer'>
                        {loading ? <MDBIcon icon="spinner" id='loader' /> : null}
                        <button 
                            className={ 
                                requestAsPrivateDisabled && requestAsCompanyDisabled ? 
                                'submitForm' : 'submitForm active'
                            }
                            type='submit'
                            disabled={requestAsPrivateDisabled && requestAsCompanyDisabled}>
                            {i18next.t('contact.submit')}
                            <span className='arrow'></span>
                        </button>
                    </div>
                    { successfullySubmitted &&
                        <p className="successMessage"><MDBIcon icon="check" /> 
                            {i18next.t('contact.success-message')}
                        </p>
                    }
                </div>
            </form>
        </div>
    );
};

export default ContactForm