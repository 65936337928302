import React from 'react';
import { MDBModal, MDBIcon } from 'mdbreact';

const FooterMapModal = (props) => {

    return (
        <MDBModal 
            className='mapModal' 
            size="lg" 
            isOpen={props.mapModal} 
            toggle={props.toggleMapModal}
            >
            <div className='modalBody'>
                <img src='/images/costum-map.webp' alt='' className='mapImage' />
                <button className='xButton' toggle={props.toggleMapModal}>
                    <MDBIcon icon="times" onClick={props.toggleMapModal}/>
                </button>
            </div>
        </MDBModal>
    );
};

export default FooterMapModal;