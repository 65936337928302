import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const WhyUs = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            scale: 1.70
        },
        visible: { 
            opacity: 1,
            scale: 1
        }
    };

    const iconVariants = {
        hidden: {
            opacity: 0,
            scale: 0.65
        },
        visible: { 
            opacity: 1, 
            scale: 1
        }
    };

    return (
        <div className='whyUs' ref={ref}>
            <motion.h3 
                className='subtitle col-12 p-0'
                animate={inView ? 'visible' : 'hidden'}
                variants={titleVariants}
                transition={{ duration: 0.5, type: 'spring'}}
                >
                {i18next.t('about.why-us-title')}
            </motion.h3>
            <motion.div 
                className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                animate={inView ? 'visible' : 'hidden'}
                variants={iconVariants}
                transition={{
                    delay: 0.2,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <div className='wrapper'>
                    <span className='icon icon-speed'></span>
                    <h4 className='label'>{i18next.t('why-us.quick')}</h4>
                </div>
            </motion.div>
            <motion.div 
                className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                animate={inView ? 'visible' : 'hidden'}
                variants={iconVariants}
                transition={{
                    delay: 0.25,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <div className='wrapper'>
                    <span className='icon icon-tradition'></span>
                    <h4 className='label'>{i18next.t('about.inherit')}</h4>
                </div>
            </motion.div>
            <motion.div 
                className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                animate={inView ? 'visible' : 'hidden'}
                variants={iconVariants}
                transition={{
                    delay: 0.3,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <div className='wrapper'>
                    <span className='icon icon-technology'></span>
                    <h4 className='label'>{i18next.t('why-us.advanced')}</h4>
                </div>
            </motion.div>
            <motion.div 
                className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                animate={inView ? 'visible' : 'hidden'}
                variants={iconVariants}
                transition={{
                    delay: 0.35,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <div className='wrapper'>
                    <span className='icon icon-price'></span>
                    <h4 className='label'>{i18next.t('why-us.prices')}</h4>
                </div>
            </motion.div>
            <motion.div 
                className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                animate={inView ? 'visible' : 'hidden'}
                variants={iconVariants}
                transition={{
                    delay: 0.4,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <div className='wrapper'>
                    <span className='icon icon-leader'></span>
                    <h4 className='label'>{i18next.t('about.quality')}</h4>
                </div>
            </motion.div>
            <motion.div 
                className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                animate={inView ? 'visible' : 'hidden'}
                variants={iconVariants}
                transition={{
                    delay: 0.45,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <div className='wrapper'>
                    <span className='icon icon-support'></span>
                    <h4 className='label'>{i18next.t('about.support')}</h4>
                </div>
            </motion.div>
        </div>
    );
};

export default WhyUs;