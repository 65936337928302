import React, { useContext } from 'react';

import { NavbarAnimateContext } from '../../../contexts/NavbarAnimateContext';
import { MDBIcon } from 'mdbreact';
import UpDrawerNav from './UpDrawerNav';
import i18next from 'i18next';

const UpDrawer = () => {
    const {toggle} = useContext(NavbarAnimateContext);
    let drawerClasses = 'upDrawer';
    if (toggle) {
        drawerClasses += ' open';
    };
    return (
        <nav className={drawerClasses}>
            <UpDrawerNav />
            <ul className={
                toggle ?
                    'upDrawerNavItems shown' :
                    'upDrawerNavItems hidden'
                }>
                <li className='navItem'>
                    <a className='navLink' href='/about'>{i18next.t('nav-links.company')}
                    </a>
                </li>
                <li className='navItem'>
                    <a className='navLink' href='/projects'>{i18next.t('nav-links.our-work')}
                    </a>
                </li>
                <li className='navItem'>
                    <a className='navLink' href='/services'>{i18next.t('nav-links.services')}
                    </a>
                </li>
                <li className='navItem'>
                    <a className='navLink' href='/contact'>{i18next.t('nav-links.contact')}
                    </a>
                </li>
                <li className='navItem'>
                    <a className='navLink' href='https://panel.geocad-ks.com/map' target={'_blank'}>{i18next.t('nav-links.lidar')}
                    </a>
                </li>
                <li className='navItem'>
                    <hr className='navLine' />
                </li>
            </ul>
            <div className='phoneWrapper'>
                <h4 className={
                    toggle ? 
                        'phoneNumber shown' :
                        'phoneNumber hidden'
                }><MDBIcon icon="phone-volume" />
                    {" "}+38344627960
                </h4>
            </div>
        </nav>
    );
};

export default UpDrawer;
