import React from 'react';
import '../style/Services.scss';
import '../style/RespServices.scss';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import OurServices from '../components/servicesComponents/OurServices';
import HowItWorks from '../components/servicesComponents/HowItWorks';
import Cadastral from '../components/servicesComponents/Cadastral';
import ContactUs from '../components/about/ContactUs';
import AllServices from '../components/servicesComponents/AllServices';

const Services = () => {

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>GEOCAD - Shërbimet</title>
        <meta name="description" content="GEOCAD - Shërbime gjeodezike dhe inxhinierike më cilësore në vend." />
        <link rel='canonical' href='https://geocad-ks.com/services' />
      </Helmet>
      <div className='services'>
        <header className='servicesHeader'>
          <motion.div className='title-container col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'
            initial={{
              opacity: 0,
              y: 30
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            transition={{
              delay: 0.2,
              duration: 1,
              type: 'spring'
            }}>
            <h1 className='title'>{i18next.t('services.header-title')}</h1>
            <h3 className='subtitle'>{i18next.t('services.header-descr')}</h3>
          </motion.div>
        </header>
        <OurServices />
        <HowItWorks />
        <Cadastral />
        <AllServices />
        <ContactUs />
      </div>
    </>
  );
}

export default Services;