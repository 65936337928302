import React from 'react';
import i18next from 'i18next';
import '../../style/Services.scss';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const Cadastral = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const textVariants = {
        hidden: {
            opacity: 0,
            x: 100
        },
        visible: { 
            opacity: 1, 
            x: 0
        }
    };

    const imgVariants = {
        hidden: {
            opacity: 0,
            x: -100
        },
        visible: { 
            opacity: 1, 
            x: 0
        }
    };

    return (
        <div className='ourCadaster' ref={ref}>
            <div className='imgWrapper col-12 col-sm-12 col-md-12 order-md-1 col-lg-5 col-xl-5 p-0'>
                <motion.img 
                    src='/images/png/cadastral.webp' 
                    alt='' 
                    className='picture' 
                    animate={inView ? 'visible' : 'hidden'}
                    variants={imgVariants}
                    transition={{ duration: 0.5, type: "spring" }}
                    />
            </div>
            <div className='text-wrapper col-12 col-sm-12 col-md-12 order-md-2 col-lg-6 col-xl-7 p-0'>
                <motion.h4 
                    className='descr'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    transition={{ duration: 0.5, type: "spring" }}
                    >
                    {i18next.t('services.summary')}
                    <br/>
                    <br/>
                    {i18next.t('services.summary2')}
                </motion.h4>
            </div>
        </div>
    );
};

export default Cadastral;