import React from 'react';
import '../style/Contact.scss';
import '../style/RespContact.scss';
import { Helmet } from 'react-helmet';

import ContactSection from '../components/home/ContactSection';

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>GEOCAD - Kontakti</title>
        <meta name='description' content='GEOCAD - Kontakto Gjeodetin tëndë dhe realizo projektet tua!' />
        <link rel='canonical' href='https://geocad-ks.com/contact' />
      </Helmet>
      <ContactSection />
    </>
  );
}

export default Contact;