import React, { Component } from 'react';
import '../style/Home.scss';
import '../style/RespHome.scss';
import { Helmet } from 'react-helmet';
import YellowSection from '../components/home/YellowSection';
import Header from '../components/home/Header';
import LanguageButtons from '../components/home/LanguageButtons';
import ProjectSection from '../components/home/ProjectSection';
import ServicesSection from '../components/home/ServicesSection';
import VerticalSlider from '../components/home/VerticalSlider';
import IndustriesSection from '../components/home/IndustriesSection';
import OrangeSection from '../components/home/OrangeSection';
import ContactSection from '../components/home/ContactSection';
import Loader from '../components/Loader';

class Home extends Component {

  render () {
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <meta name="description" content="GEOCAD - Kompania e licensuar për të gjitha shërbimet e kadastrit dhe gjeodezisë" />
          <title>GEOCAD - Faqja zyrtare</title>
          <link rel='canonical' href='https://geocad-ks.com/' />
        </Helmet>
        <Loader />
        <div className='home'>
          <img src='/images/png/whiteLogo.png' alt='GEOCAD' className='brandLogo' />
          <header className='imageContainer'>
            <LanguageButtons />
            <Header />
          </header>
          <VerticalSlider />
          <ServicesSection />
          <OrangeSection />
          <IndustriesSection />
          <ProjectSection  />
          <YellowSection />
          <ContactSection id='contact' />
        </div>
      </>
    );
  }
}

export default Home;