import React, { useState, lazy, Suspense } from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const ServiceModals = lazy(() => import('../modals/ServiceModals'))

const ServicesSection = () => {

    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    function toggleModal(id, event) {
        event.preventDefault();
        setModalId(id);
        setShowModal(showModal => !showModal);
    }

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            scale: 1.70,
        },
        visible: { 
            opacity: 1, 
            scale: 1
        }
    };

    const servicesVariants = {
        hidden: {
            opacity: 0,
            scale: 0.65
        },
        visible: { 
            opacity: 1, 
            scale: 1
        }
    };

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ServiceModals
                    modalId={modalId} 
                    show={showModal} 
                    modalToggle={() => setShowModal(showModal => !showModal)}
                />
            </Suspense>
            <div className='servicesSection' ref={ref}>
                <div className='col-12 title-container'>
                    <motion.h3
                        animate={inView ? 'visible' : 'hidden'}
                        variants={titleVariants}
                        transition={{ duration: 0.5, type: "spring" }}
                        >
                        {i18next.t('services-section.our-services')}
                    </motion.h3>
                    <motion.a href='/services' className='allServices'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={titleVariants}
                        transition={{ duration: 0.2, type: "spring" }}
                        >
                        {i18next.t('services-section.all-services')}
                        <span className='arrow'></span>
                    </motion.a>
                </div>
                <motion.div 
                    className='wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 engineering'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={servicesVariants}
                    transition={{
                        delay: 0.2,
                        duration: 0.6,
                        type: 'spring'
                    }}
                    >
                    <button onClick={(event) => toggleModal('engineering', event)}>
                        <div className='serviceCard'>
                            <div className='imgFramer col-11 p-0'>
                                <img src='/images/engineering.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                            <div className='overlayer col-9'>
                                <h4>{i18next.t('services-section.engineering')}</h4>
                                <p>
                                    {i18next.t('services-section.engineering-descr')}
                                </p>
                                <span className='arrow'></span>
                            </div>
                        </div>
                    </button>
                </motion.div>
                <motion.div className='wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 cadaster'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={servicesVariants}
                    transition={{
                        delay: 0.25,
                        duration: 0.6,
                        type: 'spring'
                    }}
                    >
                    <button onClick={(event) => toggleModal('cadaster', event)}>
                        <div className='serviceCard resp'>
                            <div className='imgFramer col-11 p-0'>
                                <img src='/images/cadaster.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                            <div className='overlayer col-9'>
                                <h4>{i18next.t('services-section.cadaster')}</h4>
                                <p>
                                    {i18next.t('services-section.cadaster-descr')}
                                </p>
                                <span className='arrow'></span>
                            </div>
                        </div>
                    </button>
                </motion.div>
                <motion.div className='wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 expertise'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={servicesVariants}
                    transition={{
                        delay: 0.3,
                        duration: 0.6,
                        type: 'spring'
                    }}
                    >
                    <button onClick={(event) => toggleModal('expertise', event)}>
                        <div className='serviceCard'>
                            <div className='imgFramer col-11 p-0'>
                                <img src='/images/expertise.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                            <div className='overlayer col-9'>
                                <h4>{i18next.t('services-section.expertise')}</h4>
                                <p>
                                    {i18next.t('services-section.expertise-descr')}
                                </p>
                                <span className='arrow'></span>
                            </div>
                        </div>
                    </button>
                </motion.div>
                <motion.div className='wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 arialSurveying'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={servicesVariants}
                    transition={{
                        delay: 0.35,
                        duration: 0.6,
                        type: 'spring'
                    }}
                    >
                    <button onClick={(event) => toggleModal('arialSurvey', event)}>
                        <div className='serviceCard'>
                            <div className='imgFramer col-11 p-0'>
                                <img src='/images/aerial-surveying.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                            <div className='overlayer col-9'>
                                <h4>{i18next.t('services-section.aerial')}</h4>
                                <p>
                                    {i18next.t('services-section.aerial-descr')}
                                </p>
                                <span className='arrow'></span>
                            </div>
                        </div>
                    </button>
                </motion.div>
            </div>
        </>
    );
}
 
export default ServicesSection;