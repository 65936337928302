import React from 'react';
import i18next from 'i18next';
import '../../style/PageModals.scss';
import '../../style/RespModals.scss';
import { MDBIcon, MDBModal } from 'mdbreact';

const ServiceModals = (props) => {

    function _inxhinieri() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.modalToggle}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/engineering-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.modalToggle}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('services-section.engineering')}
                </h1>
                <p className='descr'>
                    {i18next.t('services-modal.engineering1')}
                    <br /> <br />
                    {i18next.t('services-modal.engineering2')}
                    <br /> <br />
                    {i18next.t('services-modal.engineering3')}
                </p>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.modalToggle}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _kadaster() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.modalToggle}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/cadaster-wp.webp' alt='Kadastër' className='img cadasterImg'/>
                </div>
                <button className='xButton' onClick={props.modalToggle}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('services-section.cadaster')}
                </h1>
                <p className='descr'>
                    {i18next.t('services-modal.cadaster1')}
                    <br /> <br />
                    {i18next.t('services-modal.cadaster2')}
                </p>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.modalToggle}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _ekspertize() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.modalToggle}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/expertise-wp.webp' alt='Kadastër' className='img expertiseImg'/>
                </div>
                <button className='xButton' onClick={props.modalToggle}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('services-section.expertise')}
                </h1>
                <p className='descr'>
                    {i18next.t('services-modal.expertise1')}
                    <br /> <br />
                    {i18next.t('services-modal.expertise2')}
                </p>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.modalToggle}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _matjeAjrore() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.modalToggle}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/aerial-surveying-wp.webp' alt='Kadastër' className='img aerialSurvey'/>
                </div>
                <button className='xButton' onClick={props.modalToggle}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('services-section.aerial')}
                </h1>
                <p className='descr'>
                    {i18next.t('services-modal.aerial1')}
                    <br /> <br />
                    {i18next.t('services-modal.aerial2')}
                </p>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.modalToggle}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _modelim() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.modalToggle}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/model-3d-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.modalToggle}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('services.model-3d')}
                </h1>
                <p className='descr'>
                    {i18next.t('services-modal.3d-model1')}
                    <br /> <br />
                    {i18next.t('services-modal.3d-model2')}
                </p>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.modalToggle}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _hartografi() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.modalToggle}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/cartography-wp.webp' alt='Kadastër' className='img cartographyImg'/>
                </div>
                <button className='xButton' onClick={props.modalToggle}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('services.cartography')}
                </h1>
                <p className='descr'>
                    {i18next.t('services-modal.cartography1')}
                    <br /> <br />
                    {i18next.t('services-modal.cartography2')}
                </p>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.modalToggle}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    switch (props.modalId) {
        case 'engineering':
            return _inxhinieri();
        case 'cadaster':
            return _kadaster();
        case 'expertise':
            return _ekspertize();
        case 'arialSurvey':
            return _matjeAjrore();
        case '3dModel':
            return _modelim();
        case 'cartography':
            return _hartografi();
        default:
            return null;
    }
};

export default ServiceModals;