import React from 'react';
import i18next from 'i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ContactUs = () => {

    const [ref, inView] = useInView({
        threshold: 1,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    };

    const buttonVariants = {
        hidden: {
            opacity: 0,
            x: 50
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    }
      
    return (
        <>
            <div className='contactUs' ref={ref}>
                <div className='title-container'>
                    <motion.h1 
                        className='title'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={titleVariants}
                        transition={{ duration: 0.5, type: 'spring'}}
                        >
                        {i18next.t('contact-section.title')}
                    </motion.h1>
                    <motion.h4 
                        className='subtitle'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={titleVariants}
                        transition={{ duration: 0.5, delay: 0.1, type: 'spring'}}>
                        {i18next.t('contact-section.subtitle')}
                    </motion.h4>
                </div>
                <motion.div 
                    className='button-container'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={buttonVariants}
                    transition={{ duration: 0.5, delay: 0.2, type: 'spring'}}
                    >
                    <a href='/contact' className='contact'>{i18next.t('contact-section.cta')}<span className='arrow'></span></a>
                </motion.div>
            </div>
            <div id='divider'>
                <hr />
            </div>
        </>
    );
};

export default ContactUs;