import React, { useContext } from 'react';

import { NavbarAnimateContext } from '../../../contexts/NavbarAnimateContext';

const UpDrawerNav = () => {
    const {toggle, animate} = useContext(NavbarAnimateContext);
    return(
        <>
            <div className={toggle ? 'upDrawerToolbar' : 'upDrawerToolbar disabled'}>
                <div 
                    className={ toggle ? 
                        'navBrand__toggle shown' :
                        'navBrand__toggle hidden'}
                    onClick={ animate }>
                    <img src='/images/png/blue-logo.png' className='brandLogo' alt=' ' />
                </div>

                <div className='spacer' />

                <div className={
                    animate ?
                    'enable' :
                    'disable'
                }>
                    <button 
                        onClick={animate}
                        className={toggle ? 
                            'closeSlideButton ex' :
                            'closeSlideButton'}>
                        <span className='line__toggle'></span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default UpDrawerNav;