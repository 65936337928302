import React, { useState, lazy, Suspense, useRef } from 'react';
import i18next from 'i18next';
import { MDBIcon } from 'mdbreact';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const ProjectModals = lazy(() => import('../modals/ProjectModals'));

const ProjectSection = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    function toggleModal(id, event) {
        event.preventDefault();
        setModalId(id);
        setShowModal(showModal => !showModal);
    }
    const wrapper = useRef(null);

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });
    const titleVariants = {
        hidden: {
            opacity: 0,
            scale: 1.70,
        },
        visible: { 
            opacity: 1, 
            scale: 1 
        }
    };
    const projectsVariants = {
        hidden: {
            opacity: 0,
            scale: 0.65
        },
        visible: { 
            opacity: 1, 
            scale: 1
        }
    };

    return (
        <>
            <Suspense fallback={<div>Loading...</div>} ref={wrapper}>
                <ProjectModals
                    modalId={modalId} 
                    show={showModal} 
                    toggleModal={() => setShowModal(showModal => !showModal)}
                />
            </Suspense>
        <div className='projects' ref={ref}>
            <div className='col-12 p-0 title-container'>
                <motion.h3
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    transition={{ duration: 0.5, type: "spring" }}
                    >
                    {i18next.t('projects-section.title')}
                </motion.h3>
                <motion.a href='/projects' className='allProjects'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    transition={{ duration: 0.2, type: "spring" }}
                    >
                    {i18next.t('projects-section.all-projects')}<span className='arrow'></span>
                </motion.a>
            </div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'
                animate={inView ? 'visible' : 'hidden'}
                variants={projectsVariants}
                transition={{
                    delay: 0.2,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <button onClick={(event) => toggleModal('siu', event)} className='button'>
                    <div className='project'>
                        <div className='titleDescr col-7'>
                            <div className='title'>
                                <span>{i18next.t('industries.digitalize')}</span>
                                <h4>Kadastri i Ujrave - SIU</h4>
                            </div>
                            <p>{i18next.t('projects-section.siu-descr')}</p>
                            <span className='arrow'></span>
                            <div className='date ml-auto'>
                                <span><MDBIcon icon="calendar-check" />{' '}{i18next.t('projects-section.siu-date')}</span>
                            </div>
                        </div>
                        <span className='projectImg col-5'>
                            <img className='picture' src='/images/siu-project.webp' alt='Kadastër' />
                        </span>
                    </div>
                </button>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'
                animate={inView ? 'visible' : 'hidden'}
                variants={projectsVariants}
                transition={{
                    delay: 0.25,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <button onClick={(event) => toggleModal('mountains', event)} className='button'>
                    <div className='project'>
                        <div className='titleDescr col-7'>
                            <div className='title'>
                                <span>{i18next.t('industries.ecology')}</span>
                                <h4>{i18next.t('projects-section.mountain')}</h4>
                            </div>
                            <p>{i18next.t('projects-section.mountain-descr')}</p>
                            <span className='arrow'></span>
                            <div className='date ml-auto'>
                                <span><MDBIcon icon="calendar-check" />{' '}{i18next.t('projects-section.mountain-date')}</span>
                            </div>
                        </div>
                        <span className='projectImg col-5'>
                            <img className='picture' src='/images/mountain-project.webp' alt='Kadastër' />
                        </span>
                    </div>
                </button>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 resp'
                animate={inView ? 'visible' : 'hidden'}
                variants={projectsVariants}
                transition={{
                    delay: 0.35,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <button onClick={(event) => toggleModal('roads', event)} className='button'>
                    <div className='project'>
                        <div className='titleDescr col-7'>
                            <div className='title'>
                                <span>{i18next.t('industries.transport')}</span>
                                <h4>{i18next.t('projects-section.roads')}</h4>
                            </div>
                            <p>{i18next.t('projects-section.roads-descr')}</p>
                            <span className='arrow'></span>
                            <div className='date ml-auto'>
                                <span><MDBIcon icon="calendar-check" />{' '}{i18next.t('projects-section.roads-date')}</span>
                            </div>
                        </div>
                        <span className='projectImg col-5'>
                            <img className='picture' src='/images/road-project.webp' alt='Kadastër' />
                        </span>
                    </div>
                </button>
            </motion.div>
            <motion.div className='wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'
                animate={inView ? 'visible' : 'hidden'}
                variants={projectsVariants}
                transition={{
                    delay: 0.3,
                    duration: 0.5,
                    type: 'spring'
                }}
                >
                <button onClick={(event) => toggleModal('implant', event)} className='button'>
                    <div className='project'>
                        <div className='titleDescr col-7'>
                            <div className='title'>
                                <span>{i18next.t('industries.ecology')}</span>
                                <h4>{i18next.t('projects-section.implant')}</h4>
                            </div>
                            <p>{i18next.t('projects-section.implant-descr')}</p>
                            <span className='arrow'></span>
                            <div className='date ml-auto'>
                                <span><MDBIcon icon="calendar-check" />{' '}{i18next.t('projects-section.implant-date')}</span>
                            </div>
                        </div>
                        <span className='projectImg col-5'>
                            <img className='picture' src='/images/sewage-project.webp' alt='Kadastër' />
                        </span>
                    </div>
                </button>
            </motion.div>
        </div>
        </>
    );
}
 
export default ProjectSection;