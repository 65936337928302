import React from 'react';
import i18next from 'i18next';
import '../../style/Services.scss';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const HowItWorks = () => {

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            scale: 1.70,
        },
        visible: { 
            opacity: 1, 
            scale: 1
        }
    };

    const stepVariants = {
        hidden: {
            opacity: 0,
            scale: 0.1
        },
        visible: {
            opacity: 1,
            scale: 1
        }
    };

    return (
        <div className='howItWorks' ref={ref}>
            <div className='title-container col-12 p-0'>
                <motion.h1 
                    className='theTitle'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    transition={{ duration: 0.5, type: "spring" }}
                    >
                    {i18next.t('services.how-title')}
                </motion.h1>
            </div>
            <div className='wrapper col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                <motion.div 
                    className='theStep'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={stepVariants}
                    transition={{ duration: 0.5, delay: 0.2, type: "spring" }}
                    >
                    <img src='/images/png/the-land.webp' alt='' className='picture' />
                    <h2 className='title'>{i18next.t('services.step1')}</h2>
                    <h4 className='subtitle'>{i18next.t('services.step1-descr')}</h4>
                </motion.div>
            </div>
            <div className='wrapper col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                <motion.div 
                    className='theStep'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={stepVariants}
                    transition={{ duration: 0.5, delay: 0.25, type: "spring" }}
                    >
                    <img src='/images/png/the-survey.webp' alt='' className='picture' />
                    <h2 className='title'>{i18next.t('services.step2')}</h2>
                    <h4 className='subtitle'>{i18next.t('services.step2-descr')}</h4>
                </motion.div>
            </div>
            <div className='wrapper col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                <motion.div 
                    className='theStep'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={stepVariants}
                    transition={{ duration: 0.5, delay: 0.3, type: "spring" }}
                    >
                    <img src='/images/png/the-project.webp' alt='' className='picture' />
                    <h2 className='title'>{i18next.t('services.step3')}</h2>
                    <h4 className='subtitle'>{i18next.t('services.step3-descr')}</h4>
                </motion.div>
            </div>
        </div>
    );
};

export default HowItWorks;