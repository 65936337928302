import React, { useContext } from 'react';
import { NavbarAnimateContext } from '../../contexts/NavbarAnimateContext';


const NavToggleButton = () => {
    const {toggle, animate} = useContext(NavbarAnimateContext);
    return (
    <>
        <button 
            className={ 
                toggle ? 
                    'burgerButton animate' :
                    'burgerButton' }
            onClick={animate}>
            <span className='line'></span>
        </button>
    </>
    );
};

export default NavToggleButton;