import React from 'react';
import { motion } from 'framer-motion';

const VerticalSlider = () => {
    return (
        <motion.div className='verticalSlide'
            initial={{
                opacity: 0,
                rotate: 90,
            }}
            animate={{
                opacity: 1,
            }}
            transition={{
                delay: 1
            }}>
            GEOCAD
            <span className='slideWrapper'>
                <span className='slideItem'></span>
            </span>
        </motion.div>
    );
}
 
export default VerticalSlider;