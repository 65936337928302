import React, { useState, lazy, Suspense } from 'react';
import '../../style/Services.scss';
import i18next from 'i18next';
import { motion } from 'framer-motion';

const ServiceModals = lazy(() => import('../modals/ServiceModals'));

const OurServices = () => {

    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    function toggleModal(id, event) {
        event.preventDefault();
        setModalId(id);
        setShowModal(showModal => !showModal);
    }

    const boxVariants = {
        hidden: {
            scale: 0.3,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1
        }
    };

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ServiceModals
                    modalId={modalId} 
                    show={showModal} 
                    modalToggle={() => setShowModal(showModal => !showModal)}
                    />
            </Suspense>
            <div className='ourServices'>
                <div className='all-services col-12 p-0'>
                    <motion.div 
                        className='wrapper col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                        variants={boxVariants}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            delay: 0.2,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <button className='btn' onClick={(event) => toggleModal('engineering', event)}>
                            <div className='imgFramer'>
                                <span className='overlayer'>
                                    <h2 className='title'>{i18next.t('services-section.engineering')} <span className='arrow'></span></h2>
                                </span>
                                <img src='/images/engineering.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                        </button>
                    </motion.div>
                    <motion.div 
                        className='wrapper col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                        variants={boxVariants}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            delay: 0.25,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <button className='btn' onClick={(event) => toggleModal('cadaster', event)}>
                            <div className='imgFramer'>
                                <span className='overlayer'>
                                    <h2 className='title'>{i18next.t('services-section.cadaster')} <span className='arrow'></span></h2>
                                </span>
                                <img src='/images/cadaster.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                        </button>
                    </motion.div>
                    <motion.div 
                        className='wrapper col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                        variants={boxVariants}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            delay: 0.3,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <button className='btn' onClick={(event) => toggleModal('expertise', event)}>
                            <div className='imgFramer'>
                                <span className='overlayer'>
                                    <h2 className='title'>{i18next.t('services-section.expertise')} <span className='arrow'></span></h2>
                                </span>
                                <img src='/images/expertise.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                        </button>
                    </motion.div>
                    <motion.div 
                        className='wrapper col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                        variants={boxVariants}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            delay: 0.35,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <button className='btn' onClick={(event) => toggleModal('arialSurvey', event)}>
                            <div className='imgFramer'>
                                <span className='overlayer'>
                                    <h2 className='title'>{i18next.t('services-section.aerial')} <span className='arrow'></span></h2>
                                </span>
                                <img src='/images/aerial-surveying.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                        </button>
                    </motion.div>
                    <motion.div 
                        className='wrapper col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                        variants={boxVariants}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            delay: 0.4,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <button className='btn' onClick={(event) => toggleModal('3dModel', event)}>
                            <div className='imgFramer'>
                                <span className='overlayer'>
                                    <h2 className='title'>{i18next.t('services.model-3d')} <span className='arrow'></span></h2>
                                </span>
                                <img src='/images/model-3d.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                        </button>
                    </motion.div>
                    <motion.div 
                        className='wrapper col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'
                        variants={boxVariants}
                        initial='hidden'
                        animate='visible'
                        transition={{
                            delay: 0.45,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <button className='btn' onClick={(event) => toggleModal('cartography', event)}>
                            <div className='imgFramer'>
                                <span className='overlayer'>
                                    <h2 className='title'>{i18next.t('services.cartography')} <span className='arrow'></span></h2>
                                </span>
                                <img src='/images/cartography.webp' alt='Kadastër' className='imgCard'/>
                            </div>
                        </button>
                    </motion.div>
                </div>
            </div>
        </>
    );
};

export default OurServices;