import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';

const NavbarLinks = () => {

  return (
    <ul className='navItems'>
        <li className='navItem'>
          <NavLink className='navLink' activeClassName='navActive' to="/about">{i18next.t('nav-links.company')}</NavLink>
        </li>
        <li className='navItem'>
          <NavLink className='navLink' activeClassName='navActive' to='/projects'>{i18next.t('nav-links.our-work')}</NavLink>
        </li>
        <li className='navItem'>
          <NavLink className='navLink' activeClassName='navActive' to="/services">{i18next.t('nav-links.services')}</NavLink>
        </li>
        <li className='navItem'>
          <NavLink className='navLink' activeClassName='navActive' to="/contact">{i18next.t('nav-links.contact')}</NavLink>
        </li>
        <li className='navItem'>
          <a href={'https://panel.geocad-ks.com/map'} className='navLink' target={'_blank'}>{i18next.t('nav-links.lidar')}</a>
        </li>
    </ul>
  );
}
 
export default NavbarLinks;
