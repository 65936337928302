import React from 'react';
import i18next from 'i18next';
import ContactForm from './ContactForm';
import '../../style/Home.scss';
import '../../style/RespHome.scss';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const ContactSection = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const imgVariants = {
        hidden: {
            opacity: 0,
            x: 100,
        },
        visible: { 
            opacity: 1, 
            x: 0 
        }
    };

    return (
        <div className='contact' ref={ref}>
            <div className='title-container col-12 col-sm-12 col-md-12 col-lg-11 col-xl-8 p-0'>
                <h3>{i18next.t('contact.contact-us')}</h3>
                <h5>{i18next.t('contact.intro')}</h5>
            </div>
            <div className='form'>
                <ContactForm />
            </div>
            <motion.img 
                src='/images/png/yellow-helmet.webp' 
                className='yellow-helmet' alt=' ' 
                animate={inView ? 'visible' : 'hidden'}
                variants={imgVariants}
                transition={{ duration: 0.8, delay: 0.5, type: "spring" }}
            />
        </div>
    )
}

export default ContactSection;