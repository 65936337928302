import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const WhyUs = () => {

    const [ref, inView] = useInView({
        threshold: 0.35,
        triggerOnce: true
    });

    const variants = {
        hidden: {
            opacity: 0,
            x: -100,
        },
        visible: { 
            opacity: 1, 
            x: 0 
        }
    };

    return(
        <div ref={ref}>
            <motion.div className='col-12 p-0 whyUs'
                animate={inView ? 'visible' : 'hidden'}
                variants={variants}
                transition={{ duration: 0.5, type: "spring" }}
                >
                <h1 className='title'>{i18next.t('why-us.why')}</h1>
            </motion.div>
            <div className='reasons col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0'>
                <motion.div className='reason col-12 p-0'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 0.5, delay: 0.2, type: "spring" }}
                    >
                    <span className='icon icon-speed'></span>
                    <h4 className='label'>{i18next.t('why-us.quick')}</h4>
                </motion.div>
                <motion.div className='reason col-12 p-0'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 0.5, delay: 0.25, type: "spring" }}
                    >
                    <span className='icon icon-technology'></span>
                    <h4 className='label'>{i18next.t('why-us.advanced')}</h4>
                </motion.div>
                <motion.div className='reason col-12 p-0'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 0.5, delay: 0.3, type: "spring" }}
                    >
                    <span className='icon icon-price'></span>
                    <h4 className='label'>{i18next.t('why-us.prices')}</h4>
                </motion.div>
                <motion.div className='aboutBtn'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 0.5, delay: 0.35, type: "spring" }}>
                    <a href='/about' className='button'>{i18next.t('why-us.about-us')}<span className='arrow'></span></a>
                </motion.div>
            </div>
        </div>
    );
}

export default WhyUs;