import React, { useState } from 'react';
import '../../style/Footer.scss';
import '../../style/RespFooter.scss';
import i18next from 'i18next';
import FooterMapModal from './FooterMapModal';
import ProjectsModals from '../modals/ProjectModals';
import ServiceModals from '../modals/ServiceModals';
import { MDBIcon } from 'mdbreact';

const Footer = () => {
  const [map, setMap] = useState(false);
  const toggleMapModal = () => {
    setMap(map => !map);
  };

  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState('');
  function toggleModal(id, event) {
      event.preventDefault();
      setModalId(id);
      setShowModal(showModal => !showModal);
  }

  const changeLanguage = option => {
    localStorage.setItem('lang', option.target.value);
    window.location.reload();
  };

  const lang = localStorage.getItem('lang') || 'sq';

  return (
    <>
      <ProjectsModals 
        modalId={modalId} 
        show={showModal} 
        toggleModal={() => setShowModal(showModal => !showModal)}
        />
      <ServiceModals
        modalId={modalId} 
        show={showModal} 
        modalToggle={() => setShowModal(showModal => !showModal)}
        />
      <div className='footer'>
        <div className='mainContainer col-12'>
            <div className='logoBox col-6 col-sm-6 col-md-5 col-lg-5 col-xl-2 p-0 order-1'>
              <a href='/'><span className='icon icon-logo' /></a>
              <h6 className='address'>{i18next.t('footer.address-street')} Nr 22 <br/> {i18next.t('footer.city')}, 51000</h6>
              <button className='footerMapBtn' onClick={toggleMapModal}>{i18next.t('footer.map')}</button>
              <FooterMapModal 
                mapModal={map}
                toggleMapModal={toggleMapModal}/>
            </div>
            <div className='linkBox col-12 col-sm-12 col-md-3 col-lg-3 col-xl-8 p-0 order-3 order-sm-3 order-md-2 order-lg-2 order-xl-2'>
              <div className='wrapper pages'>
                <h4 className='title'>{i18next.t('footer.browse')}</h4>
                <ul className='listItems'>
                  <li className='listItem'><button className='modalLink'><a href='/'>{i18next.t('nav-links.home')}</a></button></li>
                  <li className='listItem'><button className='modalLink'><a href='/about'>{i18next.t('nav-links.company')}</a></button></li>
                  <li className='listItem'><button className='modalLink'><a href='/projects'>{i18next.t('nav-links.our-work')}</a></button></li>
                  <li className='listItem'><button className='modalLink'><a href='/services'>{i18next.t('nav-links.services')}</a></button></li>
                  <li className='listItem'><button className='modalLink'><a href='/contact'>{i18next.t('nav-links.contact')}</a></button></li>
                  <li className='listItem'><button className='modalLink'><a href='https://panel.geocad-ks.com/map' target={'_blank'}>{i18next.t('nav-links.lidar')}</a></button></li>
                </ul>
              </div>
              <div className='wrapper services'>
                <h4 className='title'>{i18next.t('footer.services')}</h4>
                <ul className='listItems'>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('engineering', event)}>{i18next.t('services-section.engineering')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('cadaster', event)}>{i18next.t('services-section.cadaster')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('expertise', event)}>{i18next.t('services-section.expertise')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('arialSurvey', event)}>{i18next.t('services-section.aerial')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('3dModel', event)}>{i18next.t('services.model-3d')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('cartography', event)}>{i18next.t('services.cartography')}</button></li>
                </ul>
              </div>
              <div className='wrapper projects'>
                <h4 className='title'>{i18next.t('footer.projects')}</h4>
                <ul className='listItems'>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('siu', event)}>{i18next.t('footer.siu')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('expropriation', event)}>{i18next.t('footer.expropriation')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('migration', event)}>{i18next.t('footer.migration')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('mountains', event)}>{i18next.t('footer.mountain')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('implant', event)}>{i18next.t('footer.topographic')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('geoSurveying', event)}>{i18next.t('footer.geo-survey')}</button></li>
                  <li className='listItem'><button className='modalLink' onClick={(event) => toggleModal('roads', event)}>{i18next.t('footer.roads')}</button></li>
                </ul>
              </div>
            </div>
            <div className='language col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 p-0 order-2 order-sm-2 order-md-3 order-lg-3 order-xl-3'>
              <h5 className='title'>{i18next.t('footer.language')}</h5>
              <div className='selectContainer'>
                  <select 
                      className='chooseLang' 
                      onChange={changeLanguage} 
                      value={lang}
                      >
                      <option value="sq">{i18next.t('footer.alb')}</option>
                      <option value="en">{i18next.t('footer.eng')}</option>
                  </select>
                  <span className='costumArrow'><MDBIcon icon="arrow-left" className="arrow-left" /></span>
              </div>
              <div className='smIcons'>
                  <a className='smIcon' href='https://www.facebook.com/geocadks' rel="noopener noreferrer" target="_blank"><MDBIcon fab icon="facebook-square" /></a>
                  <a className='smIcon' href='https://www.linkedin.com/company/18268911/admin/' rel="noopener noreferrer" target="_blank"><MDBIcon fab icon="linkedin" /></a>
              </div>
            </div>
        </div>
        <div className='copyrights col-12'>
          <hr className='line'/>
          <span className='endNote'>{i18next.t('footer.rights')} <strong>© N.Sh. GEOCAD - 2021</strong></span>
        </div>
      </div>
    </>
  );
}

export default Footer;
