import React from 'react';
import i18next from 'i18next';

import Statistics from './Statistics';

import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const PartnerSection = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            scale: 1.70
        },
        visible: { 
            opacity: 1,
            scale: 1
        }
    };

    const logoVariants = {
        hidden: {
            opacity: 0,
            y: 30
        },
        visible: { 
            opacity: 1,
            y: 0
        }
    }

    return (
        <div className='partnerSection' ref={ref}>
            <Statistics />
            <motion.h3 
                className='descr'
                animate={inView ? 'visible' : 'hidden'}
                variants={titleVariants}
                transition={{ duration: 0.5, type: 'spring'}}
                >
                {i18next.t('why-us.partners-title')}
            </motion.h3>
            <motion.div 
                className='partners col-12 p-0'
                animate={inView ? 'visible' : 'hidden'}
                variants={logoVariants}
                transition={{ duration: 0.5, delay: 0.2, type: 'spring'}}>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/ministria.png' alt='' className='logo' />
                </span>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/agjencia-kadastrale.png' alt='' className='logo' />
                </span>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/mabetex.png' alt='' className='logo' />
                </span>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/elkos.png' alt='' className='logo' />
                </span>
            </motion.div>
            <motion.div 
                className='partners col-12 p-0'
                animate={inView ? 'visible' : 'hidden'}
                variants={logoVariants}
                transition={{ duration: 0.5, delay: 0.3, type: 'spring'}}
                >
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/kartvertket.png' alt='' className='logo' />
                </span>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/lika.png' alt='' className='logo' />
                </span>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/dahlem.png' alt='' className='logo' />
                </span>
                <span className='wrapper col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
                    <img src='/images/png/devolli.png' alt='' className='logo' />
                </span>
            </motion.div>
        </div>
    )
}

export default PartnerSection;
