import React, { useState } from 'react';
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { MDBIcon } from 'mdbreact';

const LanguageButtons = () => {
    const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem('lang'));

    const changeLanguage = code => () => {
        localStorage.setItem('lang', code);
        setActiveLanguage(code)
        window.location.reload();
    };

    const variants = {
        hidden: {
            scale: 0,
            opacity: 0
        },
        show: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 0.4,
                delay: 0.5
            }
        }
    };

    const { scrollYProgress } = useViewportScroll();
    const langBtnY = useTransform(
        scrollYProgress,
        [0, 0.01, 0.09],
        ['0%', '0%', '-132%']
    );
   
    return (
        <motion.div className='buttonsContainer'
            initial='hidden'
            animate='show'
            variants={variants}
            style={{y: langBtnY}}
            >
            <MDBIcon icon="globe" className='globeIcon' />
            <button className={ activeLanguage === 'en'? 'en langActive' : 'en'} onDoubleClick={changeLanguage('en')}>EN</button>
            <button className={ activeLanguage === 'sq'? 'sq langActive' : 'sq'} onDoubleClick={changeLanguage('sq')}>SQ</button>
        </motion.div>
    );
}
 
export default LanguageButtons;