import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import TopconTS from './TopconTS';
import Falcon8 from './Falcon8';
import TopconGPS from './TopconGPS';
import Dji4Pro from './Dji4Pro';
import LeicaTS from './LeicaTS';
import SokkiaGPS from './SokkiaGPS';

const Tools = () => {

  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true
  });

  const titleVariants = {
      hidden: {
          opacity: 0,
          x: -50
      },
      visible: { 
          opacity: 1,
          x: 0
      }
  };

  return (
    <div className='tools'>
        <div className='title-container' ref={ref}>
          <motion.h1 
            className='title'
            animate={inView ? 'visible' : 'hidden'}
            variants={titleVariants}
            transition={{ duration: 0.5, type: 'spring'}}
            >
            {i18next.t('about.tools-header')}
          </motion.h1>
          <motion.h4 
            className='subtitle'
            animate={inView ? 'visible' : 'hidden'}
            variants={titleVariants}
            transition={{ duration: 0.5, delay: 0.1, type: 'spring'}}
            >
            {i18next.t('about.tools-subtitle')}
          </motion.h4>
        </div>
        <TopconTS />
        <Falcon8 />
        <TopconGPS />
        <LeicaTS />
        <Dji4Pro />
        <SokkiaGPS />
    </div>
  )
}

export default Tools;
