import React from 'react';
import i18next from 'i18next';
import '../../style/Services.scss';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const AllServices = () => {

    const [ref, inView] = useInView({
        threshold: 0.4,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            y: 50
        },
        visible: { 
            opacity: 1,
            y: 0
        }
    };

    const serviceVariants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    };

    return (
        <div className='allServices' ref={ref}>
            <div className='title-container col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 p-0'>
                <motion.h3 
                    className='title'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    transition={{ duration: 0.5, type: 'spring'}}>
                    {i18next.t('services.all-services-title')}
                </motion.h3>
            </div>
            <div className='entities col-12 p-0'>
                <div className='business col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0'>
                    <div className='category'>
                        <motion.h2 
                            className='categoryTitle'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.25, type: 'spring'}}
                            >
                            {i18next.t('services.business')}
                        </motion.h2>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.3, type: 'spring'}}
                            >
                            <span className='icon icon-monitorim' />
                            <h3 className='descr'>{i18next.t('contact.monitorim')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.35, type: 'spring'}}
                            >
                            <span className='icon icon-topografi' />
                            <h3 className='descr'>{i18next.t('contact.topografi')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.4, type: 'spring'}}
                            >
                            <span className='icon icon-modelim-3d' />
                            <h3 className='descr'>{i18next.t('contact.modelim')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.45, type: 'spring'}}
                            >
                            <span className='icon icon-matje-ajrore' />
                            <h3 className='descr'>{i18next.t('contact.aerial')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.5, type: 'spring'}}
                            >
                            <span className='icon icon-hartografi' />
                            <h3 className='descr'>{i18next.t('contact.hartografi')}</h3>
                        </motion.div>
                    </div>
                </div>
                <div className='person col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0'>
                    <div className='category'>
                        <motion.h2 
                            className='categoryTitle'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.25, type: 'spring'}}
                            >
                            {i18next.t('services.person')}
                        </motion.h2>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.3, type: 'spring'}}
                            >
                            <span className='icon icon-legalizim' />
                            <h3 className='descr'>{i18next.t('contact.legalizim')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.35, type: 'spring'}}
                            >
                            <span className='icon icon-ndarja-prones' />
                            <h3 className='descr'>{i18next.t('contact.ndarje')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.4, type: 'spring'}}
                            >
                            <span className='icon icon-kufizim' />
                            <h3 className='descr'>{i18next.t('contact.kufizim')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.45, type: 'spring'}}
                            >
                            <span className='icon icon-relievim' />
                            <h3 className='descr'>{i18next.t('contact.relievim')}</h3>
                        </motion.div>
                        <motion.div 
                            className='wrapper'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={serviceVariants}
                            transition={{ duration: 0.5, delay: 0.5, type: 'spring'}}
                            >
                            <span className='icon icon-expertize' />
                            <h3 className='descr'>{i18next.t('contact.ekspertize')}</h3>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllServices;