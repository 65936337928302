import React, { useState, lazy, Suspense } from 'react';
import i18next from 'i18next';
import '../style/Projects.scss';
import '../style/RespProjects.scss';
import { Helmet } from 'react-helmet';
import Expropriation from '../components/projects/Expropriation';
import SIU from '../components/projects/SIU';
import Migration from '../components/projects/Migration';
import TrackMarking from '../components/projects/TrackMarking';
import TopoSurvey from '../components/projects/TopoSurvey';
import GeoSurveying from '../components/projects/GeoSurveying';
import Streets from '../components/projects/Streets';
import ContactUs from '../components/about/ContactUs';

import { motion } from 'framer-motion';

const ProjectModals = lazy(() => import('../components/modals/ProjectModals'));

const Projects = () => {

  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState('');
  function toggleModal(id, event) {
      event.preventDefault();
      setModalId(id);
      setShowModal(showModal => !showModal);
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>GEOCAD - Projektet</title>
        <meta name="description" content="GEOCAD - Projektet e realizuara nga Geocad. Sistemi Informativ Ujor (SIU) apo Kadastri i Ujërave; Elaboratet e shpronësimit; Traseja e ecjes në Bjeshkët e Nemura; Projektimi dhe matja e rrugës Deçan - Plavë." />
        <link rel='canonical' href='https://geocad-ks.com/projects' />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <ProjectModals
          modalId={modalId} 
          show={showModal} 
          toggleModal={() => setShowModal(showModal => !showModal)}
        />
      </Suspense>
      <div className="projects">
        <header className="projectsHeader">
          <motion.div 
            className='title-container col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'
            initial={{
              opacity: 0,
              y: 30
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            transition={{
              delay: 0.2,
              duration: 1,
              type: 'spring'
            }}>
            <h1 id='ourStory'>{i18next.t('projects.header-title')}</h1>
            <h3 id='theStory'>{i18next.t('projects.header-descr')}</h3>
          </motion.div>
        </header>
        <SIU modalToggle={(event) => toggleModal('siu', event)} />
        <Expropriation modalToggle={(event) => toggleModal('expropriation', event)} />
        <Migration modalToggle={(event) => toggleModal('migration', event)} />
        <TrackMarking modalToggle={(event) => toggleModal('mountains', event)} />
        <TopoSurvey modalToggle={(event) => toggleModal('implant', event)} />
        <GeoSurveying modalToggle={(event) => toggleModal('geoSurveying', event)} />
        <Streets modalToggle={(event) => toggleModal('roads', event)} />
        <ContactUs />
      </div>
    </>
  );
}

export default Projects;