import React from 'react';
import i18next from 'i18next';
import '../../style/PageModals.scss';
import '../../style/RespModals.scss';
import { MDBIcon, MDBModal } from 'mdbreact';

const ProjectModals = (props) => {

    function _hartimiUjerave() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/siu-project-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.siu-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.siu1')}
                    <br /> <br />
                    {i18next.t('projects-modal.siu2')}
                    <br /> <br />
                    {i18next.t('projects-modal.siu3')}
                    <br /> <br />
                    <a href='https://siu.rks-gov.net/' id='siuLink'>
                        {i18next.t('projects-modal.siu-link')}
                    </a>
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-ministria-infastruktures'></span>
                        <p className='subject'>{i18next.t('projects.siu-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-project-leader'></span>
                        <p className='subject'>{i18next.t('projects.leader')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2019 - 2020</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    };

    function _markimiBjeshkeve() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/mountain-project-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.mountain-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.mountain1')}
                    <br /> <br />
                    {i18next.t('projects-modal.mountain2')}
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-eu'></span>
                        <p className='subject'>{i18next.t('projects.mountain-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-project-leader'></span>
                        <p className='subject'>{i18next.t('projects.leader')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2018 - 2019</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _projekteRrugesh() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/road-project-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.roads-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.roads1')}
                    <br /> <br />
                    {i18next.t('projects-modal.roads2')}
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-lika'></span>
                        <p className='subject'>{i18next.t('projects.roads-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-project-leader'></span>
                        <p className='subject'>{i18next.t('projects.leader')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2018 - 2019</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _impianti() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/sewage-project-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.topographic-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.implant')}
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-kk-peja'></span>
                        <p className='subject'>{i18next.t('projects.topographic-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-coorporation'></span>
                        <p className='subject'>{i18next.t('projects.coorporation')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2018</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _shpronesimi() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/expropriation-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.expropriation-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.expropriation1')}
                    <br /> <br />
                    {i18next.t('projects-modal.expropriation2')}
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-ministria-infastruktures'></span>
                        <p className='subject'>{i18next.t('projects.expropriation-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-project-leader'></span>
                        <p className='subject'>{i18next.t('projects.leader')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2020</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _migrimi() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/migration-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.migration-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.migration')}
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-agjencia-kadastrale'></span>
                        <p className='subject'>{i18next.t('projects.migration-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-coorporation'></span>
                        <p className='subject'>{i18next.t('projects.coorporation')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2016 - 2019</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    function _urbanizem() {
        return <MDBModal className='modalWrapper' size='fluid' isOpen={props.show} toggle={props.toggleModal}>
            <div className='modalHeader'>
                <div className='themePicture'>
                    <img src='/images/geo-surveying-wp.webp' alt='Kadastër' className='img'/>
                </div>
                <button className='xButton' onClick={props.toggleModal}><MDBIcon icon="times" /></button>
            </div>
            <div className='modalBody'>
                <h1 className='title'>
                    {i18next.t('projects.modeling-title')}
                </h1>
                <p className='descr'>
                    {i18next.t('projects-modal.modeling')}
                </p>
                <div className='wrapper col-12'>
                    <span className='element'>
                        <span className='icon icon-kk-prishtina'></span>
                        <p className='subject'>{i18next.t('projects.modeling-contactor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-project-leader'></span>
                        <p className='subject'>{i18next.t('projects.leader')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2018</p>
                    </span>
                </div>
                <span className='bottom-line' />
            </div>
            <div className='modalFooter'>
                <button className='exit' onClick={props.toggleModal}>{i18next.t('services-modal.back')}<span className='arrow'></span></button>
            </div>
        </MDBModal>
    }

    switch (props.modalId) {
        case 'siu':
            return _hartimiUjerave();
        case 'mountains':
            return _markimiBjeshkeve();
        case 'roads':
            return _projekteRrugesh();
        case 'implant':
            return _impianti();
        case 'expropriation':
            return _shpronesimi();
        case 'migration':
            return _migrimi();
        case 'geoSurveying':
            return _urbanizem();
        default:
            return null;
    }
};

export default ProjectModals;