import React, { Component } from 'react';
import '../style/About.scss';
import '../style/RespAbout.scss';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import { motion } from 'framer-motion';
import Tools from '../components/about/tools/Tools';
import PartnerSection from '../components/about/PartnerSection';
import WhyUs from '../components/about/WhyUs';
import VisionSection from '../components/about/VisionSection';
import ContactUs from '../components/about/ContactUs';

class About extends Component {
  render () {
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>GEOCAD - Kompania</title>
          <meta name='description' content='GEOCAD - Kompania për Gjeodezi dhe Kadastër. Bëjmë matje kadastrale dhe gjeodezike në tërë territorin e Kosovës dhe më gjërë' />
          <link rel='canonical' href='https://geocad-ks.com/about' />
        </Helmet>
        <div className="about">
          <header className="aboutHeader">
            <motion.div 
              className='title-container col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'
              initial={{
                opacity: 0,
                y: 30
              }}
              animate={{
                opacity: 1,
                y: 0
              }}
              transition={{
                delay: 0.2,
                duration: 1,
                type: 'spring'
              }}>
              <h1 id='ourStory'>{i18next.t('about.header-title')}</h1>
              <h3 id='theStory'>{i18next.t('about.header-descr')}</h3>
            </motion.div>
          </header>
          <PartnerSection />
          <Tools />
          <WhyUs />
          <VisionSection />
          <ContactUs />
        </div>
      </>
    );
  }
}

export default About;
