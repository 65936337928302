import React from 'react';
import i18next from 'i18next';
import WhyUs from '../../components/home/WhyUs';
import { useInView } from 'react-intersection-observer';

const YellowSection = () => {

    const [ref, inView] = useInView({
        threshold: 0.35,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            x: -100,
        },
        visible: { 
            opacity: 1, 
            x: 0 
        }
    };

    const logosVariants = {
        hidden: {
            opacity: 0,
            scale: 0.65
        },
        visible: { 
            opacity: 1, 
            scale: 1
        }
    };

    return (
        <div className='yellowSection' ref={ref}>
            <div className='sideLogo col-2'>
                
            </div>
            <div className='textSection col-10 p-0'>
                <img src='/images/png/globe-landscape.webp' className='globe-cartoon' alt=' ' />
                <div className='col-12 col-sm-12 col-md-11 col-lg-10 p-0'>
                    <h4 className='intro'>
                        {i18next.t('why-us.intro')}
                    </h4>
                    <div className='partners'>
                        <div className='col-12 p-0 m-0 d-flex justify-content-center'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={titleVariants}
                            transition={{ duration: 0.5, type: "spring" }}
                            >
                            <h4 className='partners-title'>{i18next.t('why-us.partners-title')}</h4>
                        </div>
                        <div className='col-12 partnerLogos'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={logosVariants}
                            transition={{ duration: 0.5, delay: 0.2, type: "spring" }}
                            >
                            <img src='/images/png/ministria.png' alt='' className='logo' />
                            <img src='/images/png/agjencia-kadastrale.png' alt='' className='logo' />
                            <img src='/images/png/mabetex.png' alt='' className='logo' />
                            <img src='/images/png/elkos.png' alt='' className='logo' />
                        </div>
                        <div className='col-12 partnerLogos'
                            animate={inView ? 'visible' : 'hidden'}
                            variants={logosVariants}
                            transition={{ duration: 0.5, delay: 0.25, type: "spring" }}
                            >
                            <img src='/images/png/kartvertket.png' alt='' className='logo' />
                            <img src='/images/png/lika.png' alt='' className='logo' />
                            <img src='/images/png/dahlem.png' alt='' className='logo' />
                            <img src='/images/png/devolli.png' alt='' className='logo' />
                        </div>
                    </div>
                    <WhyUs />
                </div>
            </div>
        </div>
    );
}
 
export default YellowSection;