import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import common_sq from './locales/sq/sq.json';
import common_en from './locales/en/en.json';

const resources = {
    en: {
        translation: common_en
    },

    sq: {
        translation: common_sq
    }
};

i18n
.use(initReactI18next)
.init({
    resources,
    keySeparator: '.',
    interpolation: {
        escapeValue: false
    },
});

export default i18n;