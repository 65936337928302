import React from 'react'
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const OrangeSection = () => {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const variants = {
        hidden: {
            opacity: 0,
            x: -100,
        },
        visible: { 
            opacity: 1, 
            x: 0 
        }
    };

    return(
        <div className='orangeSection' ref={ref}>
            <div className='col-2 sideLogo'>
                
            </div>
            <div className='textSection col-10 p-0'>
                <span className='theLine'></span>
                <img src='/images/png/home-illustration.webp' className='home-illustration' alt=' ' />
                <div className='col-12 col-sm-12 col-md-11 col-lg-10 p-0'>
                    <motion.h1
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
                        transition={{ duration: 0.5, type: "spring" }}
                        >
                        {i18next.t('when-us.when-title')}</motion.h1>
                    <motion.h4 className='subtitle'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
                        transition={{ duration: 0.5, delay: 0.1, type: "spring" }}
                        >
                        {i18next.t('when-us.when-subtitle')}
                    </motion.h4>
                    <motion.div className='wrapper col-12 col-sm-11 col-md-11 col-lg-10 col-xl-10 p-0'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
                        transition={{
                            delay: 0.25,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <span className='icon-build icon col-1'></span>
                        <span className='text col-11 col-sm-11 col-md-11 col-lg-8 col-xl-7'>
                            <h4>{i18next.t('when-us.when-build')}</h4>
                            <p>{i18next.t('when-us.whne-build-descr')}</p>
                        </span>
                    </motion.div>
                    <motion.div className='wrapper col-12 col-sm-11 col-md-11 col-lg-10 col-xl-10 p-0'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
                        transition={{
                            delay: 0.3,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <span className='icon-legalize icon col-1'></span>
                        <span className='text col-11 col-sm-11 col-md-11 col-lg-8 col-xl-7'>
                            <h4>{i18next.t('when-us.when-legalize')}</h4>
                            <p>{i18next.t('when-us.when-legalize-descr')}</p>
                        </span>
                    </motion.div>
                    <motion.div className='wrapper col-12 col-sm-11 col-md-11 col-lg-11 col-xl-10 p-0'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
                        transition={{
                            delay: 0.35,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <span className='icon-expand icon col-1'></span>
                        <span className='text col-11 col-sm-11 col-md-11 col-lg-8 col-xl-7'>
                            <h4>{i18next.t('when-us.when-expand')}</h4>
                            <p>{i18next.t('when-us.when-expand-descr')}</p>
                        </span>
                    </motion.div>
                    <motion.div className='wrapper col-12 col-sm-11 col-md-11 col-lg-10 col-xl-10 p-0'
                        animate={inView ? 'visible' : 'hidden'}
                        variants={variants}
                        transition={{
                            delay: 0.4,
                            duration: 0.5,
                            type: 'spring'
                        }}
                        >
                        <span className='icon-inheritate icon col-1'></span>
                        <span className='text col-11 col-sm-11 col-md-11 col-lg-8 col-xl-7'>
                            <h4>{i18next.t('when-us.when-inherit')}</h4>
                            <p>{i18next.t('when-us.when-inherit-descr')}</p>
                        </span>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default OrangeSection;