import React from 'react';
import i18next from 'i18next';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <motion.div 
        className='row w-100 landingPage'
        initial={{
          opacity: 0,
          y: 30
        }}
        animate={{
          opacity: 1,
          y: 0
        }}
        transition={{
          delay: 0.3,
          duration: 1,
          type: 'spring'
        }}>
        <div className='row p-0 m-0'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-7 headingContainer p-0'>
            <h1 className='mainTitle'>{i18next.t('home-header-quote')}</h1>
            <Link to='contact' className='quoteButton' spy={true} smooth={true}>{i18next.t('home-cta')} <span className='arrow'></span></Link>
          </div>
        </div>
    </motion.div>
  );
}
 
export default Header;