import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Loader = () => {
    const [present, setPresent] = useState(true);

    useEffect(() => {
        if (parseInt(sessionStorage.getItem('loading')) === 1) {
            setPresent(false)
        } else {
            setTimeout(() => {
                setPresent(false)
                sessionStorage.setItem('loading', 1);
            }, 3000);
        }
    }, [])

    const containerVariants = {
        animate: {
            y: [-10, 10],
            transition: {
                y: {
                    yoyo: Infinity,
                    duration: 0.5,
                    ease: 'easeIn'
                }
            }
        }
    };

    return (
        <div className={ present ? 'loaderContainer' : 'loaderContainer hidden' }>
            <motion.div 
                className='loader' 
                variants={containerVariants}
                animate='animate'
                >
                <span className='logo icon icon-logo'></span>
            </motion.div>
        </div>
    );
};

export default Loader;