import React from 'react';
import { MDBIcon } from 'mdbreact';
import { motion, useViewportScroll, useTransform } from "framer-motion";
import '../style/App.scss';

const ScrollToTop = () => {

    const { scrollYProgress } = useViewportScroll();
    const scrollUp = useTransform(
        scrollYProgress,
        [0, 0.09, 0.1],
        ['0px', '0px', '-80px']
    );

    const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'})

    return (
        <motion.span className='scrollToTop'
            style={{y:scrollUp}}
            >
            <span className='arrow' onClick={scrollToTop}><MDBIcon icon="arrow-up" /></span>
        </motion.span>
    )
}

export default ScrollToTop
