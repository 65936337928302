import React, { Component } from 'react';
import './style/App.scss';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ReactGA from 'react-ga';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Contact from './pages/Contact';
import NavbarAnimateContextProvider from './contexts/NavbarAnimateContext';
import ScrollToTop from './components/ScrollToTop';

class App extends Component {

  initializeAnalytics() {
    ReactGA.initialize('G-EWF8G9VKHJ');
    ReactGA.pageview('/home');
  };

  render () {
    this.initializeAnalytics();
    return (
      <>
        <Router>
          <ScrollToTop />
          <NavbarAnimateContextProvider>
            <Navbar />
          </NavbarAnimateContextProvider>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/about' component={About} />
            <Route path='/projects' component={Projects} />
            <Route path='/services' component={Services} />
            <Route path='/contact' component={Contact} />
          </Switch>
          <Footer />
        </Router>
      </>
    );
  }
}

export default App;
