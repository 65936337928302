import React from 'react';
import i18next from 'i18next';
import { motion } from 'framer-motion';

const Statistics = () => {

  const statsVariants = {
    hidden: {
        opacity: 0,
        scale: 0.65
    },
    visible: { 
        opacity: 1, 
        scale: 1
    }
  };

  return (
    <motion.div 
      variants={statsVariants}
      initial='hidden'
      animate='visible'
      transition={{duration: 0.5, delay: 0.25, type: 'spring'}}
      className='breifStats col-11 col-sm-11 col-md-11 col-lg-11 col-xl-10 p-0'
      >
      <div className='wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 projects'>
        <div className='stat'>
          <span className='pictureContainer'>
            <img src='/images/png/done-projects.png' alt='' className='picture' />
          </span>
          <h1 className='num'>40+</h1>
          <h4 className='descr col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8'>
            {i18next.t('about.stats-projects')}
          </h4>
        </div>
      </div>
      <div className='wrapper wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 p-0 business'>
        <div className='stat'>
          <span className='pictureContainer'>
            <img src='/images/png/businesses.png' alt='' className='picture' />
          </span>
          <h1 className='num'>25+</h1>
          <h4 className='descr col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8'>
            {i18next.t('about.stats-partners')}
          </h4>
        </div>
      </div>
      <div className='wrapper wrapper col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 p-0 business'>
        <div className='stat'>
          <span className='pictureContainer'>
            <img src='/images/png/done-expertises.png' alt='' className='picture' />
          </span>
          <h1 className='num'>3500+</h1>
          <h4 className='descr col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8'>
            {i18next.t('about.stats-expertises')}
          </h4>
        </div>
      </div>
    </motion.div>
  )
}

export default Statistics;
