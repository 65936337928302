import '../../style/Navbar.scss';
import '../../style/RespNavbar.scss';

import { MDBNavItem, MDBNavbarBrand, MDBNavbarNav, MDBIcon } from "mdbreact";
import React, { useContext } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

import NavToggleButton from './NavToggleButton';
import { NavbarAnimateContext } from '../../contexts/NavbarAnimateContext';
import NavbarLinks from './NavbarLinks';
import UpDrawer from './upDrawer/UpDrawer';

const Navbar = props => {
  const { toggle } = useContext(NavbarAnimateContext);

  if(toggle) {
    document.getElementById('body').classList.add('toggleBody');
  } else {
    document.getElementById('body').classList.remove('toggleBody');
  }

  const { scrollYProgress } = useViewportScroll();
  const navbarY = useTransform(
    scrollYProgress,
    [0, 0.01, 0.09],
    ['0%', '0%', '-100%']
  )
  
  return (
    <>
      <UpDrawer />
      <motion.nav 
        className='theNav' 
        expand="lg"
        style={{
          y: navbarY
        }}
        initial={{
          y: -80
        }}
        animate={{
          y: 0
        }}
        transition={{
          delay: 0.3,
          duration: 0.5,
          ease: 'easeInOut'
        }}>
        <MDBNavbarBrand className={
          toggle ? 
              'navBrand hidden' :
              'navBrand'}>
          <a href='/' className='p-0'>
              <img src='/images/png/white-logo.png' className='brandLogo' alt=' ' />
          </a>
        </MDBNavbarBrand>
        <div className='spacer' />
        <NavToggleButton/>
        <MDBNavbarNav className='navWraper'>
          <NavbarLinks />
          <MDBNavItem className='d-flex ml-auto'>
            <h5 className='phoneNumber'><MDBIcon icon="phone-volume" /> +38344627960</h5>
          </MDBNavItem>
        </MDBNavbarNav>
      </motion.nav>
    </>
  );
}
 
export default Navbar;