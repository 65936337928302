import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const TopconTS = () => {

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
    });

    const titleVariants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    };

    const imageVariants = {
        hidden: {
            opacity: 0,
            x: 50
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    }

    return (
        <div className='theTool gray' ref={ref}>
            <div className='descr col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0'>
                <motion.h2 
                    className='name'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    transition={{ duration: 0.5, type: 'spring'}}
                    >
                    {i18next.t('about.topcon-ts-title')}
                </motion.h2>
                <motion.h4 
                    className='specification'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={titleVariants}
                    transition={{ duration: 0.5, delay: 0.1, type: 'spring'}}
                    >
                    {i18next.t('about.topcon-ts-descr')}
                </motion.h4>
            </div>
            <motion.div 
                className='wrapper col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 p-0'
                animate={inView ? 'visible' : 'hidden'}
                variants={imageVariants}
                transition={{ duration: 0.5, delay: 0.3, type: 'spring'}}
                >
                <img src='/images/png/topcon-GT-series.webp' alt='' className='picture' />
            </motion.div>
        </div>
    );
};

export default TopconTS;