import React from 'react';
import i18next from 'i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const Migration = (props) => {

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
    });

    const textVariants = {
        hidden: {
            opacity: 0,
            x: -100
        },
        visible: { 
            opacity: 1,
            x: 0
        }
    };

    const imageVariants = {
        hidden: {
            opacity: 0,
            x: 100
        },
        visible: { 
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.5,
                type: 'spring'
            }
        }
    };

    const buttonVariants = {
        hidden: {
            opacity: 0,
            x: -30
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 0.35,
                duration: 0.1,
                type: 'spring'
            }
        }
    };

    return (
        <div className='section migration' ref={ref}>
            <div className='imgContainer col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5'>
                <motion.img 
                    src='/images/png/migration.webp' 
                    alt='' 
                    className='picture'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={imageVariants}
                    />
            </div>
            <div className='text-wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <motion.div 
                    className='title-container'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    transition={{duration: 0.5, delay: 0.2, type: 'spring'}}
                    >
                    <h2 className='title'>{i18next.t('projects.migration-title')}</h2>
                    <h4 className='descr'>{i18next.t('projects.migration-descr')}</h4>
                </motion.div>
                <motion.div 
                    className='wrapper'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    transition={{duration: 0.5, delay: 0.25, type: 'spring'}}
                    >
                    <span className='element'>
                        <span className='icon icon-agjencia-kadastrale'></span>
                        <p className='subject'>{i18next.t('projects.migration-contractor')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon icon-coorporation'></span>
                        <p className='subject'>{i18next.t('projects.coorporation')}</p>
                    </span>
                    <span className='element'>
                        <span className='icon-calendar icon'></span>
                        <p className='subject'>2016 - 2019</p>
                    </span>
                </motion.div>
                <motion.span 
                    className='bottom-line'
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    transition={{duration: 0.5, delay: 0.3, type: 'spring'}} 
                    />
                <motion.button 
                    className='moreBtn' 
                    onClick={props.modalToggle}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={buttonVariants}
                    >
                    {i18next.t('projects.more')}
                    <span className='arrow'></span>
                </motion.button>
            </div>
        </div>
    );
};

export default Migration;